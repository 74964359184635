


















































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { ACT_LOGIN_USER } from "@/store/actions/types";
import {
  notificationNetworkError,
  notifyError,
} from "@/components/Notification";
import rules from "@/components/account/rules";
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
import { axiosBase, getAPI } from "@/api/axios-base";
import { apiBaseUrl } from "@/enviorment";
import axios from "axios";

export default Vue.extend({
  name: "Login",
  // inject: ["callCenterProvider"],
  data() {
    return {
      loging: false,
      validForm: false,
      dialogTwoFa: false,
      loadingTwoFA: false,
      showPass: false,
      code: "",
      rules: {
        email: rules.email,
        required: rules.required,
      },
      email: "",
      password: "",
      wrongCred: false, // activates appropriate message if set to true
      loading: false, // property for button loading button state
    };
  },
  computed: {
    ...mapState(["profile", "dataCRM", "tokenTwoFa", "needTwoFAuth"]),
    ...mapGetters([
      "loggedIn",
      "isSuper",
      "isAdmin",
      "isWorker",
      "isDoctor",
      "isSupervisor",
    ]),

    needTwoFA() {
      if (
        (this as any).needTwoFAuth == true ||
        ((this as any).tokenTwoFa != "" &&
          (this as any).tokenTwoFa != undefined &&
          (this as any).tokenTwoFa != null)
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    needTwoFA(val) {
      if (val == true) {
        this.dialogTwoFa = true;
      } else {
        this.dialogTwoFa = false;
      }
    },
  },
  mounted() {
    (this as any).destroyToken();
    (this as any).$parent.$parent.$parent.drawer = false;
    (this as any).$parent.$parent.$parent.topBar = false;

    this.mutContainUnread(false);
  },

  methods: {
    ...mapActions(["actGeneralConfig", "actGetContacts", "continueLogin"]),
    ...mapMutations([
      "updateStatus",
      "destroyToken",
      "mutNeedTwoFA",
      "mutTokenTwoFA",
      "updateactivenotify",
    ]),
    ...mapMutations("crmSMSModule", ["mutContainUnread"]),

    validforms() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (
        (this as any).email != "" &&
        pattern.test((this as any).email) &&
        (this as any).password != ""
      ) {
        (this as any).validForm = true;
      } else {
        (this as any).validForm = false;
      }
    },

    getContacts() {
      (this as any).actGetContacts();
    },

    async loginUser() {
      // call loginUSer action

      (this as any).mutNeedTwoFA(false);
      localStorage.removeItem("needTwoFA");
      (this as any).mutTokenTwoFA("");
      if ((this as any).validForm == false) return;
      (this as any).loging = true;

      try {
        const response = await this.$store.dispatch(ACT_LOGIN_USER, {
          email: (this as any).email,
          password: (this as any).password,
        });

        if (this.needTwoFA == false) {
          this.nextStepLogin();
          this.updateactivenotify(true);
        }
      } catch (error: any) {
        (this as any).loging = false;
        let mess = error.response.data.message.toString();
        notifyError(error.response.data, `An error occurred: ${mess}`);
      } finally {
        (this as any).loging = false;
      }
    },

    async nextStepLogin() {
      try {
        (this as any).mutTokenTwoFA("");
        await (this as any).actGeneralConfig();
        this.$vuetify.theme.themes.light = this.dataCRM.themes.light;

        (this as any).updateStatus("Idle");
        (this as any).wrongCred = false;
        (this as any).loging = false;

        this.$router.push("/clients");
      } catch (error: any) {
        (this as any).loging = false;
        let mess = error.response.data.message.toString();
        notifyError(error.response.data, `An error occurred: ${mess}`);
      }
    },
    focusPassword() {
      (this as any).$refs.password.focus();
    },

    confirmTwoFa() {
      this.loadingTwoFA = true;

      const auth =
        (this as any).tokenTwoFa != undefined &&
        (this as any).tokenTwoFa != null &&
        (this as any).tokenTwoFa != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${(this as any).tokenTwoFa}`,
            }
          : {
              Accept: "*/*",
            };

      const url = `${apiBaseUrl}/users/employee/twFalogin`;
      axios({
        method: "post",
        url: url,
        data: { code: this.code },
        headers: auth,
      })
        .then((response) => {
          this.closeConfirm();
          this.continueLogin(response.data);
          this.nextStepLogin();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
          this.loadingTwoFA = false;
        });
    },
    closeConfirm() {
      this.dialogTwoFa = false;
      this.code = "";
      this.loadingTwoFA = false;
      (this as any).mutTokenTwoFA("");
    },
    cancelConfirm() {
      this.dialogTwoFa = false;
      this.code = "";
      this.loadingTwoFA = false;
      (this as any).mutNeedTwoFA(false);
      localStorage.removeItem("needTwoFA");
      (this as any).mutTokenTwoFA("");
    },
  },
  updated() {
    this.validforms();
  },
});
